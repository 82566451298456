// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import $ from "jquery-with-plugins";

import Rails from "@rails/ujs";
import "semantic_ui/semantic_ui";
import CountUp from "countup.js";
import Clipboard from "clipboard";
import Slideout from "slideout";
import "./charts";
import Cropper from "cropperjs";

global.$ = $;
global.jQuery = $;
global.Rails = Rails;
global.CountUp = CountUp;
global.Clipboard = Clipboard;
global.Slideout = Slideout;
global.Cropper = Cropper;
global.Lobibox = Lobibox; // resolved in webpack.config.js

Rails.start();
