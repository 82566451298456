import $ from "jquery";
import "slick-carousel";
import "@jpeer/jquery.dotdotdot";
import "jquery.cookie";
import "jquery-form";
import "jquery-countdown";
import "jquery-tablesort";
import "simple-lightbox";
import "jquery-cropper";

$.extend({
  // Evaluates a script in a global context
  globalEval(code) {
    const script = document.createElement("script");

    script.text = code;
    script.setAttribute("nonce", Rails.cspNonce());
    document.head.appendChild(script).parentNode.removeChild(script);
  },
});

export default $;
